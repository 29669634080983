import Vuex from "vuex";

export const getStoreValues = () => {
  let state = {
      settingsCurrentSection: null,
      sections: [
        {
          id: "general",
          isEdit: false
        },
        {
          id: "professional_info",
          isEdit: false
        },
        {
          id: "learning_interest",
          isEdit: false
        },
        {
          id: "system_language",
          isEdit: false
        }
      ],
      settings: {
        form: {
          given_name: "",
          family_name: "",
          display_name: "",
          gender: "",
          email: "",
          country: "",
          city: "",
          age: "",
          role: "",
          role_tenure: "",
          interests: "",
          udf_role: "",
          udf_gender: "",
          language: ""
        }
      }
    },
    mutations = {
      SET_SETTINGS_CURRENT_SECTION(state, section) {
        state.settingsCurrentSection = section;
        let index = state.sections.findIndex(s => s.id === section.id);
        state.sections[index] = section;
      }
    },
    getters = {
      form: () => {
        return state.form;
      },
      allProfile: () => {
        return {
          given_name: "World",
          family_name: "Hello",
          display_name: "hello_w",
          gender: "Female",
          email: "a@b.c",
          age: "2",
          language: "en"
        };
      },
      allLanguage: () => {
        return [
          {
            id: "en",
            name: "English"
          },
          {
            id: "fr",
            name: "French"
          }
        ];
      },
      allSections: () => {
        return state.sections;
      }
    };

  let store = new Vuex.Store({
    state,
    mutations,
    getters
  });

  return store;
};
