import { createLocalVue, mount } from "@vue/test-utils";
import Vuex from "vuex";

import { waitRAF } from "@/../tests/utils.js";
import { getStoreValues } from "../../Shared/utils";

import SettingsCard from "../SettingsCard.vue";

// create an extended `Vue` constructor
const localVue = createLocalVue();
localVue.use(Vuex);

describe("SettingsCard.vue", () => {
  it("render when created ", async () => {
    let wrapper = await getWrapper();
    expect(wrapper.exists()).toBeTruthy();
    expect(wrapper.element).toMatchSnapshot();
    wrapper.destroy();
  });
  describe("Props", () => {
    let wrapper;

    afterEach(() => {
      wrapper.destroy();
    });

    it("should render settings card when `section` object is present", async () => {
      wrapper = await getWrapper();
      await waitRAF();
      const card = wrapper.find(".settings-card");
      expect(card.exists()).toBe(true);
    });

    it("should add class name `edit`  when 'section.isEdit` is true", async () => {
      wrapper = await getWrapper();
      await wrapper.setProps({ section: { id: "general", isEdit: false } });
      await waitRAF();
      const editCard = wrapper.find(".sc-heading__actions--edit");
      expect(editCard.exists()).toBe(true);
    });
  });
  describe("Events", () => {
    let wrapper;
    beforeEach(async () => {
      wrapper = await getWrapper();
    });
    afterEach(() => {
      wrapper.destroy();
    });
    it("should change the edit to `true` when edit is clicked", async () => {
      let card = wrapper.find(".sc-heading__actions--edit");
      await card.trigger("click");
      await waitRAF();
      expect(wrapper.find(".sc-heading__actions--edit").exists()).toBe(false);
    });
  });
});

function getWrapper() {
  let store = getStoreValues();

  let wrapper = mount(SettingsCard, {
    propsData: {
      section: {
        id: "general",
        isEdit: false
      }
    },
    localVue,
    store
  });

  return wrapper;
}
